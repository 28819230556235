<script setup lang="ts">
import { type InsightsEvent, JobSearch } from '@sozialinfo/search'
import { isNetlifyContext } from '@/lib/utils'
import { createTrackingEvent } from '@/lib/matomo'

defineProps<{
  resultsPerPage: number
  userUrl: string
}>()

const indices = {
  joboffer: import.meta.env.PUBLIC_MEILISEARCH_JOBOFFER_INDEX,
  cantons: import.meta.env.PUBLIC_MEILISEARCH_CANTONS_INDEX,
  places: import.meta.env.PUBLIC_MEILISEARCH_PLACES_INDEX,
  workingAreas: import.meta.env.PUBLIC_MEILISEARCH_WORKING_AREAS_INDEX,
  positions: import.meta.env.PUBLIC_MEILISEARCH_POSITIONS_INDEX,
  employments: import.meta.env.PUBLIC_MEILISEARCH_EMPLOYMENTS_INDEX,
  qualifications: import.meta.env.PUBLIC_MEILISEARCH_QUALIFICATIONS_INDEX,
}
const meilisearchHost = import.meta.env.PUBLIC_MEILISEARCH_HOST
const meilisearchApiKey = import.meta.env.PUBLIC_MEILISEARCH_APIKEY
const sozialinfoApiBaseUrl = import.meta.env.PUBLIC_SOZIALINFO_API_BASE_URL
const sozialinfoApiKey = import.meta.env.PUBLIC_SOZIALINFO_API_KEY

function trackEvent({ action, name }: { action: string, name: string }) {
  return createTrackingEvent({
    category: 'job-search',
    action,
    name,
    value: 0,
  })
}

function sendInsightToMatomo(event: InsightsEvent) {
  if (event.eventType !== 'click')
    return

  trackEvent({
    action: event.insightsMethod || event.eventType,
    name: event.attribute || '',
  })()
}
</script>

<template>
  <JobSearch
    :results-per-page="resultsPerPage"
    :user-url="userUrl"
    :meili-search-host="meilisearchHost"
    :meili-search-api-key="meilisearchApiKey"
    :sozialinfo-api-base-url="sozialinfoApiBaseUrl"
    :sozialinfo-api-key="sozialinfoApiKey"
    :indices="indices"
    :is-netlify-context="isNetlifyContext()"
    @insight="sendInsightToMatomo"
  />
</template>
